<div class="p-5">
    <div class="d-flex ">
        <div class="flex-grow-1">
        </div>
        <div class="w-75" style="max-width: 400px;">
            <img class="w-100" src="assets/images/under_construction.png">
        </div>
        <div class="flex-grow-1">

        </div>
    </div>
    <h1 class="text-center" *ngIf="pageName">{{pageName}}</h1>
</div>